// TODO: add all fractals here
// eslint-disable-next-line
export type {
  TLocaleKeys,
  TTranslations,
  TThemeColors,
  TGlobalTheme,
  TStrapiRes,
  TStrapiDataItem,
  TStrapiData,
  TCloudinaryImageData,
  TCloudinaryPdfData,
  TValueOfPromise,
  TAwaitedPromise,
  TAwaited
} from './types'

export {
  ENV,
  LOCALES,
  LOCALES_ARR,
  PATHS,
  THEME,
  THEME_COLORS_DEFAULT,
  TRANSLATIONS,
  BASE64PIXELS,
  JANUARY,
  FEBRUARY,
  MARCH,
  APRIL,
  MAY,
  JUNE,
  JULY,
  AUGUST,
  SEPTEMBER,
  OCTOBER,
  NOVEMBER,
  DECEMBER,
  YEAR,
  MONTH,
  PHONE_NUMBER
} from './constants'
export {
  getAccentColor,
  buildUserLocation,
  buildLeadData,
  createLeadEmailBody
} from './api'

// TODO: Remove this line and fix the error
// eslint-disable-next-line
export {
  useTranslations,
  useCalculateClosestEnrollment,
  useCalculateStudyDuration,
  useIntersection,
  useAt,
  useMedia,
  useHandleUtms,
  usePagination
} from './hooks'
export { default as StyledGlobal } from './StyledGlobal'
export {
  toUnit,
  sortBasedOnKey,
  removeKey,
  fetcher,
  parseGeneralSlug,
  handleLocale,
  handleReferer,
  handleUtms,
  handleCookiesExpiration,
  media,
  multiMedia,
  getCountryCodeByPhonePrefix,
  getPhonePrefixByCountryCode,
  phonePrefixToCountryCode,
  getCurrentJournal,
  withClickOutside
  // subscribe,
  // unsubscribe
} from './utils'
export {
  Button,
  Container,
  Header,
  Footer,
  H1,
  H2,
  H3,
  Image,
  Link,
  Main,
  P,
  IconContainer,
  Section,
  Div,
  Span,
  Ul,
  Ol,
  Li,
  InfoTag,
  Logo,
  IconArrow,
  Input,
  Form,
  Label,
  A,
  IconPlus,
  IconHamburger,
  IconCheck,
  IconCircle,
  IconPlay,
  IconUnionFolder,
  IconBelowHeaderDecoration,
  IconTelegram,
  IconVK,
  IconWhatsapp,
  IconForward,
  IconQuote,
  IconSearch,
  IconClock,
  IconTelegramFooter,
  IconVKFooter,
  IconYoutubeFooter,
  IconChat,
  IconTick,
  Select,
  Option,
  FormBalls,
  SquareLogo,
  IconScroll,
  IconEntry
} from './components'
export { default as imgLicence } from './licence.jpg'
